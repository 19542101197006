import React from 'react';
import Layout from '../components/layout';
import { Box, Text, Header, Link } from '../components/core';

const NotFoundPage = () => (
  <Layout>
    <Box px={3} py={5} mx="auto" maxWidth={800}>
      <Header textAlign="center" my={3}>
        Sorry!
      </Header>
      <Text textAlign="center">
        <p>The page you are looking for doesn't seem exist.</p>
        <Link to="/">Go to Home</Link>
      </Text>
    </Box>
  </Layout>
);

export default NotFoundPage;
